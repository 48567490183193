import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router";
import { panelArr } from "../assets/cultivatorsEventPanels";
import CardsGrid from "../components/CardsGrid";
import Loading from "../components/Loading";
import MarkDown from "../components/MarkDown";
import useEventSeries from "../hooks/useEventSeries";
import Image from "../components/Image";

const EventSeries = () => {
  const { eventSeriesID } = useParams();
  const { series, isLoading } = useEventSeries(eventSeriesID || "");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="keywords"
          content="event event-series cultivators lisbon cultiv portugal Forum"
        />
        <meta name="description" content="Event-series that is organized and funded by cultiv" />
        <meta name="robots" content="index, follow" />
        <title>{series?.title}</title>
      </Helmet>
      {isLoading ? (
        <div className="flex justify-center items-center h-0 min-h-screen">
          <Loading />
        </div>
      ) : (
        <div className="w-full flex flex-col gap-5 justify-center items-center">
          <Image
            className="object-cover w-full min-h-[140px]  sm:min-h-0"
            src={"/images/cultivators/event-series-cover.svg"}
            alt="Cultivators Cover"
          />
          <div className="w-full flex flex-col gap-6 xl:gap-10 px-6 xl:px-20">
            <div className="flex flex-col w-full gap-3 lg:gap-6">
              <h2 className="font-bold text-xl lg:text-4xl lg:pt-5">{series?.title}</h2>
              <MarkDown
                proseHeaders="prose-headings:m-3"
                proseLead="prose-lead:leading-none"
                proseList="prose-ul:list-disc"
                prosePadding="pr-8"
                articleBody={series?.md_text as string}
              />
            </div>
            <div>
              <h2 className="font-bold text-xl lg:text-4xl pb-3 lg:pb-6">Panels</h2>
              <CardsGrid cards={panelArr} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EventSeries;
