import { useNavigate } from "react-router";

const BureauContactButton = () => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center w-full">
      <button
        className="flex items-center justify-center text-white hover:text-black bg-[#1E1E1E] hover:bg-cultiv-yellow px-7 lg:px-14 py-1.5 lg:py-4"
        onClick={() => {
          navigate(`/bureau/contact`);
        }}
      >
        <p className="text-sm sm:text-2xl font-semibold">Contact Us</p>
      </button>
    </div>
  );
};

export default BureauContactButton;
