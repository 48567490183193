import { Transition } from "@headlessui/react";
import { useState } from "react";
import Image from "./Image";

export interface Props {
  imgSrc: string;
  imgAlt: string;
  cardText: string;
  hoverText: string;
}

const BureauImageCard = ({ imgAlt, imgSrc, cardText, hoverText }: Props) => {
  const [isInside, setIsInside] = useState<boolean>(false);

  return (
    <div
      className="flex flex-col col-span-4 items-center justify-center relative border-cultiv-grey border-2 w-full h-full flex-1 min-h-[370px] md:min-h-[350px] lg:min-h-[320px] xl:min-h-[400px] 3xl:min-h-[500px] 4xl:min-h-[700px]"
      onMouseEnter={() => {
        setIsInside(true);
      }}
      onMouseLeave={() => {
        setIsInside(false);
      }}
    >
      <Image width={405} height={295} src={imgSrc} alt={imgAlt} className="min-w-full" />
      <div className="relative w-full max-w-full h-full">
        <Transition
          show={isInside}
          enter="transition-opacity ease-in-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-in-out duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className={"absolute top-0 left-0 z-20 min-w-full h-full"}
        >
          <div className="flex items-center px-3 py-4 justify-center bg-cultiv-blue h-full">
            <p className="font-light text-sm xl:text-base text-center text-white">{hoverText}</p>
          </div>
        </Transition>
        <div className="min-w-full flex items-center justify-center h-full">
          <p className="font-bold text-2xl xl:text-4xl">{cardText}</p>
        </div>
      </div>
    </div>
  );
};

export default BureauImageCard;
