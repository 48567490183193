import { Link } from "react-router-dom";
import { Person } from "../schema";
import Image from "./Image";

export interface Props {
  person: Person;
  panelist?: boolean;
}

export default function PersonCard({ person, panelist }: Props) {
  return (
    <div
      className={`flex flex-col gap-2 col-span-2 lg:col-span-4 xl:col-span-3 relative w-full h-full`}
    >
      <div className="relative w-full flex justify-center items-center border-2 border-transparent overflow-hidden  aspect-square ">
        {panelist ? (
          <img
            src={person.profile_picture ? person.profile_picture : "/images/2815428.png"}
            alt={person.name}
            className="w-full transition-all transform ease-in-out duration-[800ms] hover:scale-110 aspect-square object-cover"
          />
        ) : (
          <Link to={`/profile/${person.idea_name}`} className="w-full aspect-square">
            <Image
              src={person.profile_picture ? person.profile_picture : "/images/2815428.png"}
              alt={person.name}
              className="w-full transition-all transform ease-in-out duration-[800ms] hover:scale-110 aspect-square object-cover"
            />
          </Link>
        )}
      </div>
      <div className="flex flex-col gap-1">
        {panelist ? (
          <p className="text-sm md:text-lg lg:text-2xl">{person.name}</p>
        ) : (
          <Link to={`/profile/${person.idea_name}`}>
            <p className="text-sm md:text-lg lg:text-2xl">{person.name}</p>
          </Link>
        )}
        {person.job_title &&
          (panelist ? (
            <p className="text-gray-600 font-light text-xs md:text-base lg:text-base">
              {person.job_title}
            </p>
          ) : (
            <Link to={`/profile/${person.idea_name}`}>
              <p className="text-gray-600 font-light text-xs md:text-base lg:text-lg">
                {person.job_title}
              </p>
            </Link>
          ))}
      </div>
    </div>
  );
}
