import { useNavigate, useLocation } from "react-router";
import { Tab } from "../schema";
import RightChevron from "./Icons/RightChevron";

export interface Props {
  displayed: string;
  setDisplayed: (id: string) => void;
  tabs: Tab[];
  page: string;
  resetPage?: (pageNumber: number) => void;
  returnPage?: string;
}

export default function GridController({
  displayed,
  setDisplayed,
  tabs,
  page,
  resetPage,
  returnPage,
}: Props) {
  const navigate = useNavigate();
  const location = useLocation();

  const returnPageState = returnPage || location.state?.from;

  return (
    <div className="border-b-2 border-cultiv-grey grid grid-cols-4 lg:grid-cols-12 md:pb-5 p-0 w-full">
      <div
        className={`${tabs.length < 3 ? "col-span-3" : "col-span-6"} text-2xl flex justify-between`}
      >
        {tabs.map((tab, index) => {
          return (
            <button
              key={index}
              className={`2xl:text-3xl lg:text-2xl md:text-xl text-sm text-cultiv-grey transition duration-500 hover:text-black ${
                displayed === tab.id && " !text-black"
              } `}
              onClick={() => {
                setDisplayed(tab.id);
                resetPage && resetPage(1);
                navigate(`/${page}/${tab.id}`, {
                  state: { from: returnPageState },
                });
              }}
            >
              <h3>{tab.name}</h3>
            </button>
          );
        })}
      </div>
      {returnPageState && (
        <button className="justify-self-end col-end-13" onClick={() => navigate(returnPageState)}>
          <RightChevron color={"black"} className="rotate-180"></RightChevron>
        </button>
      )}
    </div>
  );
}
