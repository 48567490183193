import { useDispatch } from "react-redux";
import { openModal } from "../redux/features/memberModalSlice";
import Image from "./Image";
import { isMobile } from "react-device-detect";
export interface Props {
  imgSrc: string;
  slug: string;
  name: string;
}

export default function MemberButton({ imgSrc, name, slug }: Props) {
  const dispatch = useDispatch();

  const openMember = (member: string) => {
    dispatch(openModal(member));
  };

  return (
    <div className="flex justify-center items-center h-full">
      <div className="flex justify-center items-center border-black w-full h-full bg-white ">
        <button
          className="duration-300 md:grayscale hover:grayscale-0 flex justify-center items-center h-full w-full"
          onClick={() => {
            {
              isMobile ? scrollTo(0, 80) : scrollTo(0, 135);
            }
            openMember(slug);
          }}
        >
          <Image src={imgSrc} alt={name} className="w-full h-full object-contain" />
        </button>
      </div>
    </div>
  );
}
