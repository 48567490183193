import BureauImageCard from "../../components/BureauImageCard";

const servicesInformation = [
  {
    src: "/images/bureau/para.svg",
    alt: "bureau",
    description: "Soft-Landing",
    hoverText:
      "Cultiv Bureau provides comprehensive support to simplify your entry and ensure a smooth transition into the Egyptian market.",
  },
  {
    src: "/images/bureau/people.svg",
    alt: "bureau",
    description: "Outsourcing",
    hoverText: "Delegate routine tasks and access a cost-effective workforce in Egypt.",
  },
  {
    src: "/images/bureau/people-gear.svg",
    alt: "bureau",
    description: "Out-Staffing",
    hoverText:
      "Supplement your team with temporary or permanent Egyptian talent for specific needs.",
  },
  {
    src: "/images/bureau/puzzle.svg",
    alt: "bureau",
    description: "Project Teams",
    hoverText:
      "Get expert project based assistance for specific projects with our skilled Egyptian professionals.",
  },
  {
    src: "/images/bureau/magnifying-glass.svg",
    alt: "bureau",
    description: "Market Research",
    hoverText:
      "Gain valuable insights into the Egyptian market and your target audience through our tailored research solutions.",
  },
];

const Services = () => {
  return (
    <div className="flex flex-col gap-6 lg:gap-10">
      <section className="flex flex-col gap-10 px-6 sm:px-20">
        <div className="flex flex-col gap-4">
          <p className="font-light md:font-normal text-sm sm:text-base md:text-xl lg:text-2xl !leading-6 md:!leading-10 lg:!leading-[50px]">
            Cultiv Bureau offers a wide range of services tailored to meet the diverse needs of
            startups and SMEs. Our core offerings include outsourcing, out-staffing, recruitment,
            technology resale, and consulting services. By leveraging our extensive network and
            expertise, we ensure that your business operations run smoothly and efficiently,
            allowing you to focus on growth and innovation.
          </p>
        </div>
      </section>
      <section className="flex flex-col gap-10">
        <div className="grid grid-cols-4 sm:grid-cols-2 md:grid-cols-8 lg:grid-cols-12 gap-4 lg:gap-7 px-6 md:px-20 xl:px-20 min-h-[100px]">
          {servicesInformation.map(({ src, alt, description, hoverText }, index) => {
            return (
              <BureauImageCard
                key={index}
                imgSrc={src}
                imgAlt={alt}
                cardText={description}
                hoverText={hoverText}
              />
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default Services;
