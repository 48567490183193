import { Disclosure, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Fragment, useState } from "react";
import RightChevron from "./Icons/RightChevron";

export interface Props {
  question: string;
  answer: string;
}

const FAQ = ({ question, answer }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Disclosure as="div" defaultOpen={false}>
      <Disclosure.Button
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className="flex items-center justify-between md:gap-40 py-3 w-full"
      >
        <span className="text-sm md:text-xl lg:text-2xl text-start font-light sm:font-normal">
          {question}
        </span>

        <RightChevron
          color={`${isOpen ? "#FFC501" : "black"}`}
          className={`w-4 sm:w-10 transition ease-linear duration-300 ${
            isOpen ? "rotate-[270deg]" : "rotate-90"
          }`}
        />
      </Disclosure.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-500 "
        enterFrom="transform opacity-0 scale-95 "
        enterTo="transform opacity-100 scale-100 "
        leave="transition ease-in duration-500 "
        leaveFrom="transform opacity-100 scale-100 "
        leaveTo="transform opacity-0 scale-95 "
      >
        <Disclosure.Panel className="text-sm md:text-xl lg:text-2xl font-light sm:font-normal text-gray-600">
          {answer}
        </Disclosure.Panel>
      </Transition>
    </Disclosure>
  );
};

export default FAQ;
