import BureauContactButton from "../../components/BureauContactButton";
import BureauPricingCard from "../../components/BureauPricingCard";

const pricingCards = [
  {
    title: "Out-staffing",
    feats: ["Recruitment", "Payroll", "Personal & Legal", "Administrative management"],
    price: "400",
  },
  {
    title: "Service outsourcing",
    feats: [
      "Recruitment",
      "Payroll",
      "Personal & Legal",
      "Administrative management",
      "Performance management",
      "Employees Relation",
      "Real-time management",
      "Reporting",
    ],
    price: "1040",
  },
  {
    title: "Project outsourcing",
    feats: [
      "Recruitment",
      "Payroll",
      "Personal & Legal",
      "Administrative management",
      "Performance management",
      "Employees Relation",
      "Real-time management",
      "Reporting",
    ],
    price: "940",
  },
];

const Pricing = () => {
  return (
    <div className="flex flex-col gap-6 lg:gap-10">
      <section className="flex items-center justify-center px-4 md:px-20">
        <p className="text-sm font-light sm:font-normal sm:text-lg md:text-xl lg:text-2xl text-center !leading-6 md:!leading-10 lg:!leading-[50px]">
          Unforeseen overhead costs can obstruct financial planning. Cultiv Bureau guarantees
          transparency by outlining your expenses upfront. Say goodbye to budget uncertainty and
          gain a clear understanding of the costs associated with running your business.
        </p>
      </section>
      <section className="grid md:grid-cols-2 xl:grid-cols-3 px-9 md:px-12 lg:px-16 xl:px-20 gap-8 py-10 bg-black">
        {pricingCards.map(({ title, feats, price }, index) => {
          return <BureauPricingCard key={index} title={title} feats={feats} price={price} />;
        })}
      </section>

      <section>
        <BureauContactButton />
      </section>
    </div>
  );
};

export default Pricing;
