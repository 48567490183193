export const bureauCardInformation = [
  {
    src: "/images/bureau/bureau5.webp",
    alt: "bureau",
    description: "Guidance",
    hoverText:
      "Receive 300 minutes of expert mentorship and support from Cultiv Bureau's mentors and industry leaders.",
  },
  {
    src: "/images/bureau/bureau4.webp",
    alt: "bureau",
    description: "Market Research",
    hoverText:
      "Optimize your market research and strategy development with dedicated office hours and tailored workshops.",
  },
  {
    src: "/images/bureau/bureau1.webp",
    alt: "bureau",
    description: "Network",
    hoverText:
      "Connect with top industry leaders in Egypt to gain valuable insights and build relationships.",
  },
  {
    src: "/images/bureau/bureau3.webp",
    alt: "bureau",
    description: "Simplified Entry",
    hoverText:
      "Enjoy discounted access to Cultiv Bureau and ideaspace infrastructure, streamlining your operations and reducing soft-landing costs.",
  },
  {
    src: "/images/bureau/bureau2.webp",
    alt: "bureau",
    description: "Talent Pool",
    hoverText:
      "Access Egypt's highly skilled and multilingual talent through outsourcing, outstaffing, or dedicated project teams.",
  },
];
