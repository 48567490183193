import { Transition } from "@headlessui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useTextTruncation from "../hooks/useTextTruncation";
import Image from "./Image";
import ReadMoreAnime from "./ReadMoreAnime";

export interface Props {
  title: string;
  imgSrc: string;
  desc: string;
  slug: string;
  cycles: number;
}

export default function ProgramCard({ title, imgSrc, desc, slug, cycles }: Props) {
  const [isInside, setIsInside] = useState<boolean>(false);
  const truncatedText = useTextTruncation(desc, 150);
  const navigate = useNavigate();
  return (
    <div
      className={`border-2 border-black border-opacity-30 h-full w-full aspect-square relative flex items-end justify-end`}
      onMouseEnter={() => {
        setIsInside(true);
      }}
      onMouseLeave={() => {
        setIsInside(false);
      }}
    >
      <Image src={imgSrc} alt={title} className="absolute top-0 left-0 w-full h-full -z-10" />

      <Transition
        show={isInside}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={`h-full w-full`}
      >
        <div
          onClick={() => {
            navigate(`/cultivation/program/${slug}`);
          }}
          className="bg-cultiv-sky justify-between text-gray-900 flex flex-1 flex-col items-start p-4 h-full w-full cursor-pointer"
        >
          <div className="flex flex-col justify-start items-start gap-1 pb-3.5">
            <h1 className="2xl:leading-8 font-bold lg:text-xl">{title}</h1>
          </div>
          <p className=" 2xl:text-base md:text-sm text-base 2xl:leading-8 md:text-start">
            {truncatedText}
          </p>
          <button className="w-full">
            <ReadMoreAnime color="black" />
          </button>
        </div>
      </Transition>
    </div>
  );
}
