import { Link } from "react-router-dom";

export default function JoinTheTeam() {
  return (
    <div
      className={`flex flex-col gap-2 col-span-2 lg:col-span-4 xl:col-span-3 relative w-full h-full border-[1px] border-solid border-black`}
    >
      <div className="relative h-32 sm:h-56 md:h-72 lg:h-64 2xl:h-80 3xl:h-[25rem] w-full flex justify-center  items-center border-2 border-transparent overflow-hidden">
        <Link to={`/careers`} className="w-full h-full">
          <img
            src="/images/joinTheTeam.svg"
            alt="Join the cultivators"
            className="w-full h-full  transition-all transform ease-in-out duration-[800ms] hover:scale-110"
          />
        </Link>
      </div>
      <div className="flex justify-center items-center">
        <Link to={`/careers`}>
          <h2 className="text-center 2xl:text-2xl md:text-lg text-sm">Join the Cultiv family</h2>
        </Link>
      </div>
    </div>
  );
}
